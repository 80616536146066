@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Outfit", sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
